<template>
  <List
    title="profiles templates"
    resource="profileTemplates"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    track-by="id"
    on-row-click="edit"
    :response-mapper="responseMapper"
    data-provider="$raaDataProvider"
    :pageSize="20"
    infinity-scroll
  >
    <template v-slot:inline-actions="actionsScope">
      <button
        class="btn-action-warning focus:outline-none"
        v-if="!actionsScope.record.deletedAt"
        @click.stop="deleteTemplate(actionsScope.record)"
      >
        <icon class="w-5 h-5 mr-1" name="trash"/>
      </button>
    </template>
  </List>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Icon from "@/components/ui/Icon";
  import List from "@/components/auth/list/List";
  import ArrayField from "@/components/auth/list/fields/ArrayField";

  export default {
    name: "ProfileTemplatesList",
    components: {
      List,
      Icon,
    },
    mixins: [ModalNavigation, ConfirmationMixin, NotifyMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
    data: function () {
      return {
        fields: [
          {
            name: 'name',
            title: 'name',
            sortField: 'name',
          },
          {
            name: 'description',
            title: 'description',
          },
          {
            name: ArrayField,
            title: 'record keys',
            switch: {
              source: 'keys',
              label: 'key',
              max: 3
            }
          },
          {
            name: 'inline-actions',
            title: '',
          }
        ],
        filter: {},
      }
    },
    methods: {
      responseMapper(resp) {
        return {
          ...resp,
          content: resp.content.filter(item => !item.deletedAt),
        }
      },
      deleteTemplate(record) {
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this template?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('profileTemplates', { id: record.id })
              .then(() => {
                this.$refs.list.doSearch();
                this.notifySuccess('Profile data block template is deleted');
              })
              .catch(error => this.notifyError(error.message))
            : null
          );
      },
    }
  }
</script>

<style scoped>

</style>
